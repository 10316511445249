<template>
  <div>
    <el-page-header @back="back" content="商品详情"> </el-page-header>
    <div class="container">
      <el-descriptions border style="margin-top: 20px">
        <el-descriptions-item label="商品ID">{{ spuDetail.id }}</el-descriptions-item>
        <el-descriptions-item label="商品名称">{{ spuDetail.name }}</el-descriptions-item>
        <el-descriptions-item label="审核状态">{{
          spuDetail.statusDes
        }}</el-descriptions-item>
        <el-descriptions-item label="是否上架">{{
          spuDetail.onShelf ? "是" : "否"
        }}</el-descriptions-item>
        <el-descriptions-item label="购买数量">{{
          spuDetail.countBuy
        }}</el-descriptions-item>
        <el-descriptions-item label="展示销量">
          {{spuDetail.salesCount}}
        </el-descriptions-item>
        <el-descriptions-item label="排序值">
          {{spuDetail.sort}}
        </el-descriptions-item>
        <el-descriptions-item label="封面图">
          <el-image
              :src="spuDetail.logoUrl"
              style="width: 100px"
              fit="contain"
          ></el-image>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions border direction="vertical">
        <el-descriptions-item label="Banner">
          <div style="display: flex; align-items: center; flex-wrap: wrap">
            <div v-for="(item, index) in bannerList" :key="index">
              <el-image
                v-if="item.type === 'image'"
                :src="item.value"
                :preview-src-list="[item.value]"
                style="width: 150px; margin-right: 30px"
                fit="contain"
              ></el-image>
              <video v-else controls height="200" width="300" :src="item.value" style="margin-right: 30px"></video>
            </div>
          </div>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions border direction="vertical">
        <el-descriptions-item label="详情图片">
          <el-image
            v-for="(item, index) in descList"
            :key="index"
            :src="item.value"
            :preview-src-list="[item.value]"
            style="width: 150px; margin-right: 30px"
            fit="contain"
          ></el-image>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        v-if="spuDetail.skuList"
        border
        title="SKU信息"
        style="margin: 20px 0"
      >
        <el-descriptions-item label="SKUID">{{
          spuDetail.skuList[0].skuId
        }}</el-descriptions-item>
        <el-descriptions-item label="市场价"
          >￥{{ spuDetail.skuList[0].marketPrice / 100 }}</el-descriptions-item
        >
        <el-descriptions-item label="售价"
          >￥{{ spuDetail.skuList[0].realPrice / 100 }}</el-descriptions-item
        >
        <!-- <el-descriptions-item label="开课时间">{{
          formatTime(spuDetail.skuList[0].startTime)
        }}</el-descriptions-item>
        <el-descriptions-item label="结课时间">{{
          formatTime(spuDetail.skuList[0].endTime)
        }}</el-descriptions-item> -->
      </el-descriptions>
    </div>
  </div>
</template>

<script>
import SpuApi from "../../request/SpuApi";
import dayjs from "dayjs";
export default {
  data() {
    return {
      spuId: null,
      spuDetail: {},
      bannerList: [],
      descList: [],
    };
  },
  mounted() {
    this.spuId = this.$route.query.id;
    this.loadData();
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    loadData() {
      SpuApi.detail({
        spuId: this.spuId,
      }).then((res) => {
        if (res.success) {
          this.spuDetail = res.data;
          this.bannerList = JSON.parse(res.data.imagesUrl);
          this.descList = JSON.parse(res.data.descJson);
        }
      });
    },
    formatTime(val) {
      return dayjs(val).format("YYYY-MM-DD HH:mm");
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: fixed;
  overflow: auto;
  top: 140px;
  width: calc(100vw - 200px - 40px);
  height: calc(100vh - 140px);
}
</style>
